import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import background from "../images/v2/Contact/Front_Banner.svg";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import SEO from "../components/SEO";
import useWidth from "../hooks/useWidth";
import Grid from "@material-ui/core/Grid";
import callImg from "../images/v2/connect-with-us/call-answer (1).svg";
import emailImg from "../images/v2/connect-with-us/Email_-_Grey (1).svg";
import whatappImg from "../images/v2/connect-with-us/whatsapp (1).svg";
import time from "../images/v2/connect-with-us/time.svg";
import calender from "../images/v2/connect-with-us/calender.svg";
import { conversionEventOnCall } from "./singapore-sme-v2";

const bussinessHours = [
  {
    title: "08.30 AM to 06.30 PM",
    img: time,
    alt: "Timing",
  },
  {
    title: (
      <span>
        Monday to Friday
        <br /> (Excluding public holidays)
      </span>
    ),
    img: calender,
    alt: "Calendar",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // [theme.breakpoints.down("sm")]: {
    background: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // },
  },
  bgImage: {
    position: "absolute",
    zIndex: -1,
    height: "auto",
    width: "100%",
  },
  img: {
    height: "auto",
    maxWidth: "100%",
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: theme.spacing(3),
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    width: "650px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formSubmit: {
    height: 50,
  },
  header: {
    paddingTop: "58px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5px",
    },
  },
  description: {
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  },
  connect: {
    color: "#fff",
    fontSize: "21px",
    fontWeight: "600",
    textAlign: "justify",
  },
  connectImage: {
    height: "18px",
    width: "18px",
  },
  connectText: {
    color: "#fff",
    marginLeft: "-23px",
    fontSize: "20px",
    "& span": {
      fontSize: "20px",
    },
  },
  connectListItem: {
    marginBottom: "-11px",
  },
  verticalLine: {
    borderLeft: "1px solid #fff",
    height: "155px",
  },
  connectContainer: {
    width: "870px",
    paddingBottom: "60px",
    paddingLeft: "34px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  verticalLineContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  containerItem1: {
    paddingLeft: "95px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  containerItem2: {
    paddingLeft: "67px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  mainHeading: {
    fontSize: "45px",
  },
  ListItemText: {
    fontSize: "20px",
  },
}));

const contact = () => {
  const width = useWidth();
  const classes = useStyles();

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/crm-support"
        description="Pepper Cloud CRM Customer Support is here to help you with any inquiries or issues you may have. Contact us today by submitting your support request now."
        keywords=" crm customer service and support, Crm support,
				Contact Us Page,
				Pepper Cloud Contact Us"
        pathname="/crm-support"
        title="Contact Customer Support | Pepper Cloud CRM"
      />
      <div className={classes.root}>
        {/*<img alt="Pepper Cloud: Sales Pipeline Background" className={classes.bgImage} src={background} />*/}
        <Box
          alignItems="center"
          className={classes.header}
          component={Container}
          display="flex !important"
          flexDirection="column"
          textAlign="center"
        >
          <Box display="flex" justifyContent="center">
            <Box maxWidth={858} pt={2} pb={4} textAlign="center">
              <HeaderTypography
                color="common.white"
                mt={{ sm: 4, xs: 4 }[width] || 0}
                component="h1"
                mb={4}
                className={classes.mainHeading}
              >
                {`We are here to help`}
              </HeaderTypography>
              <Box
                color="common.white"
                component="h2"
                fontWeight={400}
                fontSize={{ sm: 16, xs: 16 }[width] || 18}
                pb={3}
              >
                At Pepper Cloud CRM, we value your satisfaction and are
                committed to providing exceptional support to all our valued
                customers. Our dedicated support team is here to assist you with
                any inquiries or issues you may have.
              </Box>
            </Box>
            <Box />
          </Box>
          <Box>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              sm={12}
              xs={12}
              spacing={5}
              className={classes.connectContainer}
            >
              <Grid
                item
                md={5}
                sm={5}
                xs={12}
                className={classes.containerItem1}
              >
                <Box>
                  <List
                    pl={0}
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className={classes.connect}
                      >
                        Connect with us
                      </ListSubheader>
                    }
                  >
                    <a
                      href="tel:+6597510475"
                      onClick={conversionEventOnCall}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        alignItems="center"
                        className={classes.connectListItem}
                      >
                        <ListItemIcon>
                          <img src={callImg} alt="Call" />
                        </ListItemIcon>
                        <ListItemText className={classes.connectText}>
                          +65 9751 0475
                        </ListItemText>
                      </ListItem>
                    </a>
                    <a
                      href="https://wa.me/6597510475"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        alignItems="center"
                        className={classes.connectListItem}
                      >
                        <ListItemIcon>
                          <img src={whatappImg} alt="WhatsApp" />
                        </ListItemIcon>
                        <ListItemText className={classes.connectText}>
                          +65 9751 0475
                        </ListItemText>
                      </ListItem>
                    </a>
                    <a
                      href="mailto:support@peppercloud.com"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        alignItems="center"
                        className={classes.connectListItem}
                      >
                        <ListItemIcon>
                          <img src={emailImg} alt="Email" />
                        </ListItemIcon>
                        <ListItemText className={classes.connectText}>
                          support@peppercloud.com
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>
                </Box>
              </Grid>
              <Grid
                item
                md={1}
                sm={1}
                xs={1}
                style={{ paddingLeft: "37px", paddingRight: "48px" }}
                className={classes.verticalLineContainer}
              >
                <div className={classes.verticalLine}></div>
              </Grid>
              <Grid
                item
                md={5}
                sm={5}
                xs={12}
                className={classes.containerItem2}
              >
                <Box>
                  <List
                    className={classes.nopadtpbtm}
                    pl={0}
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className={classes.connect}
                      >
                        Business hours
                      </ListSubheader>
                    }
                  >
                    {bussinessHours.map((each) => (
                      <ListItem
                        alignItems="center"
                        key={each}
                        className={classes.connectListItem}
                      >
                        <ListItemIcon>
                          <img
                            src={each.img}
                            alt={each.alt}
                            className={classes.connectImage}
                          />
                        </ListItemIcon>
                        <ListItemText className={classes.connectText}>
                          {each.title}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Paper className={classes.contactUsCard}>
              <Box
                fontSize={{ sm: 20, xs: 20 }[width] || 28}
                fontWeight={600}
                margin="0 auto"
                maxWidth={460}
              >
                Let us know your issue and we will get back to you immediately
              </Box>
              <Box style={{ height: "700px" }}>
                <Box
                  border="none"
                  component="iframe"
                  height="100%"
                  id="pc-iframe"
                  overflow="hidden"
                  referrerpolicy="unsafe-url"
                  src="https://app.peppercloud.com/form/be433684-88e7-4676-afa6-22d8289d648d/embed"
                  width="99%"
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </div>

      <Box mt={5}>
        <Divider light />
      </Box>
    </React.Fragment>
  );
};

export default contact;
